<template>
  <div>
    <div class="about-hero">
      <h1>{{ $t('pages.about_us.heading') }}</h1>
    </div>
    <b-container class="container-content my-5">
      <div class="card">
        <div class="header">
          <h3>{{ $t('pages.about_us.story_of_bluuu') }}</h3>
        </div>
        <div class="content">
          <p>
            {{ $t('pages.about_us.when_i_first_moved_to_bangkok') }}
          </p>
          <p>{{ $t('pages.about_us.but_this_made_me_think') }}</p>
          <p>
            "{{ $t('pages.about_us.does_it_really_have_to_be_this_way') }}” <br />
            “{{ $t('pages.about_us.is_this_the_best_that_she_can_really_be') }}”<br />
            “{{ $t('pages.about_us.how_can_i_help_her_to_become_a_better_professional') }}”<br />
            “{{ $t('pages.about_us.how_can_i_make_this_whole_experience_less_stressful') }}”<br />
          </p>
          <p>{{ $t('pages.about_us.this_is_how_Bluuu_was_born') }}</p>
        </div>
      </div>
      <div class="card">
        <div class="header">
          <h3>{{ $t('pages.about_us.heading') }}</h3>
        </div>
        <div class="content">
          <p>{{ $t('pages.about_us.thailand_is_full_of_opportunities') }}</p>
          <p>{{ $t('pages.about_us.there_are_many_issues_that_maid_employers_face_today') }}</p>
          <p>{{ $t('pages.about_us.we_are_a_start_up_and_that_means_we_are_small') }}</p>
        </div>
      </div>

      <div class="card founder">
        <div class="header">
          <h3>{{ $t('pages.about_us.about_founder') }}</h3>
          <b-img
            alt="founder"
            :src="require('../assets/images/about/founder.jpg')"
            class="founder-avatar"
          ></b-img>
          <h3>Daigo Yoda</h3>
        </div>
        <div class="content" style="max-width: 698px; margin: auto">
          <p v-html="$t('pages.about_us.daigo_is_a_cleanest_expert')"></p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
// import { createMeta } from '../config/seo'
export default {
  // metaInfo() {
  //   return createMeta({
  //     title: this.$i18n.locale === 'th' ? 'เกี่ยวกับเรา' : 'About us',
  //     lang: this.$i18n.locale,
  //   })
  // },
}
</script>

<style lang="scss" scoped>
.about-hero {
  margin-top: 70px;
  background: url('https://dev.houseofdev.tech/assets/images/about/about-hero.jpg') no-repeat;
  background-size: cover;
  background-position: center center;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: 700;
    font-size: 3em;
  }
  @media screen and (min-width: 1280px) {
    height: 480px;
  }
}
.card {
  padding: 1em 2em;
  display: flex;
  flex-direction: row;
  border: none;
  border-left: 8px solid #005dff;
  border-radius: 0;
  background: #fff;
  box-shadow: 0 6px 9px rgba(68, 85, 113, 0.1);
  margin-bottom: 32px;
  h3 {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .header {
    min-width: 230px;
  }

  &.founder {
    border: none;
    border-top: 8px solid #005dff;
    flex-direction: column;
    .header {
      text-align: center;
      .founder-avatar {
        border: 3px solid #005dff;
        padding: 8px;
        border-radius: 200px;
        margin: 1em;
        width: 200px;
        height: 200px;
      }
    }
  }
}
.content {
  p,
  a {
    text-align: justify;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
}

.content-2 {
  p,
  a {
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
}
</style>
